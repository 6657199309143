/*General Styles*/

html, body{
  max-width: 100%;
  overflow-x: hidden;
  font-family: "Trebuchet MS", Helvetica, Arial, sans-serif;
}

body{
  margin: 0;
  padding: 0;
  font-family: "Trebuchet MS", Helvetica, Arial, sans-serif;
  background: #081b29;
  scroll-behavior: smooth;
}

h1, h2, h3, p, ul{
  margin: 0;
  padding: 0;
}

h1{
  color: #d78129;
  font-size: 32px;
}

h2{
  color: #d78129;
  font-size: 28px;
  margin-bottom: 20px;
}

h3, h4{
  color: #0071bc;
}

p{
  color: #fff;
  text-align: left;
  font-size: 20px;
  font-weight: 500;
  line-height: 31px;
  letter-spacing: 0.75px;
}

button, a, .client-images, #menu_icon, .process-item{
  -webkit-tap-highlight-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/* Header Styling */

.header {
  background: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 0 5px #0071bc;
  position: relative;
  z-index: 1000;
  width: 100%;
  padding: 15px 20px;
  transition: all 400ms ease-in-out;
}

.header.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  background: #081b29;
}

/* Logo Styling */

.logo {
  position: relative;
  display: flex;
  align-items: center;
}

.logo img {
  max-height: 130px;
  margin-right: 10px;
}

/* Navigation Styling */

#menu_icon{
  position: relative;
  font-size: 50px;
  color: #d78129;
  cursor: pointer;
  display: none;
  transition: all 300ms ease-in-out;
}

#menu_icon:hover{
  color: #0071bc;
}

nav ul {
  list-style: none;
  display: flex;
  align-items: center;
}

nav ul li {
  margin-right: 20px;
  position: relative;
}

.right-side {
  font-size: 16px;
  position: relative;
  top: 4px;
  left: 4px;
}

.left-side{
  font-size: 16.1px;
  position: relative;
  right: 7px;
}

.sub-side{
  font-size: 34px;
  position: relative;
  top: 4px;
  right: 7px;
}

nav ul li a {
  text-decoration: none;
  color: #d78129;
  font-weight: bold;
  transition: color 0.3s ease-in-out;
  padding: 20px;
  padding-bottom: 40px;
}

nav ul li a.active{
  color: #0071bc;
}

nav ul li:hover a {
  color: #0071bc;
}

/* Submenu Styling */
nav ul ul {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background: #081b29 url("/public/assets/submenu_bg.png");
  background-size: contain;
  border-radius: 5px;
  padding: 15px;
  box-shadow: 0 2px 5px #0071bc;
  margin-top: 30px;
}

nav ul li:hover > ul {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

nav ul li:hover > ul.left {
  left: auto;
  right: 0;
}

nav ul ul li {
  width: calc(33.333% - 20px); /* Adjust width based on the number of columns and gap */
  margin-bottom: 30px;
}

nav ul ul a {
  padding: 15px;
  white-space: nowrap;
  transition: all 0.5s ease-in-out;
}

nav ul ul a:hover{
  background-color: #0071bc;
  color: #081b29;
  text-decoration: underline;
  border-radius: 10px;
}

nav ul ul a.active{
  color: #d78129;
}

/* Submenu Text Styling */
.submenu-text {
  color: #0071bc;
  text-align: center;
  margin-bottom: 15px;
  white-space: nowrap;
}

.submenu-text p {
  margin: 5px 0;
  font-size: 11px;
  color: #081b29;
}

/* CTA Button Styling */

.cta-button{
  max-width: 100%;
  margin-right: 50px;
}

.cta-button a {
  background-color: #0071bc;
  color: #081b29;
  padding: 20px;
  text-decoration: none;
  font-weight: 700;
  border-radius: 10px;
  transition: all 0.5s ease-in-out;
}

.cta-button a:hover {
  background-color: #d78129;
  color: #081b29;
  box-shadow: 0 0 10px #d78129;
}

/* Introduction Section Styling */

.introduction-container {
  background: url("/public/assets/intro_glowbg.png") no-repeat;
  background-position: left;
  background-size: cover;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  align-items: center;
}

.left-section{
  display: flex;
  flex-direction: column;
  max-width: 100%;
  margin-left: 50px;
  margin-top: 10px;
}

.img-container{
  text-align: center;
  max-width: 100%;
  margin: auto;
}

.img-container img{
  width: 400px;
  height: 400px;
}

.right-section {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  margin-right: 50px;
  margin-top: 50px;
}

.rotating-header {
  color: #d78129;
  font-weight: 800;
  margin-bottom: 15px;
}

.intro-paragraph p{
  margin-bottom: 20px;
}

.intro-buttons{
  display: flex;
  justify-content: space-between;
  padding: 10px;
  margin-top: 40px;
}

 #dev_team{
  background-color: #081b29;
  color: #d78129;
  padding: 20px 40px;
  text-decoration: none;
  font-weight: 800;
  border: 3px double #d78129;
  border-radius: 10px;
  transition: all 0.5s ease-in-out;
  margin-right: 30px;
}

#dev_team:hover{
  background-color: #d78129;
  color: #081b29;
  box-shadow: 0 0 5px #e9bc77;
}

#more_about{
  background-color: #d78129;
  color: #081b29;
  padding: 20px 40px;
  text-decoration: none;
  font-weight: 800;
  border: 3px double #081b29;
  border-radius: 10px;
  transition: all 0.5s ease-in-out;
}

#more_about:hover{
  background-color: #0071bc;
  color: #081b29;
  border-color: #0071bc;
  box-shadow: 0 0 10px #0071bc;
}


/*Project Process Section Styling*/

.large-gradientbg{
  background: url("/public/assets/nbtolbtowh_bg.png") no-repeat center;
  background-size: cover;
}

.process-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
}

.process-section .process-heading h2 {
  text-align: center;
}

.process-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.process-content img {
  max-width: 100%;
}


/*Services Overview Section Styling*/

.s-overview-main{
  margin-top: 100px;
}

.s-overview-intro{
  text-align: center;
  margin: 0 50px 50px 50px;
}

.s-overview-intro h2{
  color: #d78129;
}

.s-overview-intro p{
  text-align: center;
}

.services-overview{
  display: grid;
  grid-template-columns: repeat(3, 3fr);
  gap: 20px;
  margin-left: 50px;
  margin-right: 50px;
}

.service{
  background-color: #081b29;
  padding: 30px;
  border: 3px double #0071bc;
  border-radius: 10px;
  transition: all 0.5s ease-in-out;
}

.service:hover{
  cursor: pointer;
  background-color: #0071bc;
  border-color: #0071bc;
  box-shadow: 0 0 10px #0071bc;
}

.service h3{
  color: #d78129;
  text-align: center;
  margin-top: 20px;
  transition: all 0.5s ease-in-out;
}

.service p{
  font-size: 12px;
  text-align: center;
  letter-spacing: none;
}

.service:hover span{
  color: #081b29;
  background-color: #d78129;
}

.service:hover h3{
  color: #081b29;
}

.service-icon{
  max-width: fit-content;
  margin: auto;
}

.service-icon span{
  background-color: #0071bc;
  color: #d78129;
  font-size: 98px;
  padding: 30px;
  border-radius: 10px;
  transition: all 0.5s ease-in-out;
}

.service-btn{
  max-width: fit-content;
  margin: 70px auto auto auto;
}

.service-btn button{
  background-color: #d78129;
  color: #081b29;
  padding: 20px 40px;
  text-decoration: none;
  font-weight: 800;
  border: 3px double #081b29;
  border-radius: 10px;
  transition: all 0.5s ease-in-out;
}

.service-btn button:hover{
  cursor: pointer;
  background-color: #0071bc;
  color: #081b29;
  box-shadow: 0 0 10px #081b29;
}

.bottom-mover{
  margin-bottom: 100px;
}


/* Technologies Section Styling */
.technologies-section {
  background: url("/public/assets/alive_cog.gif") no-repeat;
  background-size: cover;
}

.technologies-intro{
  text-align: center;
  position: relative;
  top: 20px;
  margin: 0 50px 50px 50px;
}

.technologies-intro h2 {
  color: #d78129;
}

.technologies-intro p {
  color: #081b29;
  text-align: center;
}

.technologies-nav ul {
  list-style: none;
  display: flex;
  justify-content: center;
}

.technologies-nav li {
  margin: 0 10px;
}

.technologies-nav a {
  color: #0071bc;
  font-size: 16px;
  text-decoration: none;
  transition: color 0.3s ease-in-out;
}

.technologies-nav a.active,
.technologies-nav a:hover {
  color: #d78129;
}

  /** Technologies Images Section Styling **/

.technologies-images {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 50px;
  overflow: hidden;
  position: relative;
  margin-top: 20px;
}

.technology-image{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-bottom: 100px;
}

.technology-image img {
  width: 120px;
  height: 120px;
  margin: 0 50px;
}

.technology-image p{
  color: #0071bc;
  font-size: 14px;
}


/*Recent Projects Section Styling*/

.recent-projects-section {
  background-image: linear-gradient(#fff, #fff, #081b29);
}

.recent-projects-intro {
  text-align: center;
  position: relative;
  top: 20px;
  margin: 0 50px 50px 50px;
}

.recent-projects-intro h2 {
  color: #d78129;
}

.recent-projects-intro p {
  color: #081b29;
  text-align: center;
}

.rp-grid-area {
  display: grid;
  grid-template-columns: repeat(2, 2fr);
  gap: 20px;
  margin: 0 50px;
}

.recent-project-item {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 2px 5px #0071bc;
  cursor: pointer;
  transition: all 500ms ease-in-out;
}

.recent-project-item:hover {
  box-shadow: 0 4px 10px #0071bc;
}

.recent-project-item img {
  max-width: 100%;
  max-height: 100%;
  display: block;
  transition: opacity 500ms ease-in-out;
}

.recent-project-item:hover img {
  opacity: 0;
}

.recent-project-item .gif {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  display: none;
}

.recent-project-item:hover img.gif {
  display: block;
  opacity: 1;
}


/*Client Recognition Section Styling*/

.client-recognition-section{
  margin-top: 100px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 50px;
}

.client-recognition-left{
  background: url("/public/assets/homeclients_bg.png") no-repeat center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  margin-left: 50px;
}

.client-recognition-left h2{
  margin-bottom: 20px;
}

.client-recognition-left p{
  margin-bottom: 20px;
}

.client-recognition-left img{
  width: 500px;
  height: 500px;
}

.client-recognition-right{
  display: flex;
  flex-direction: column;
  max-width: 100%;
  margin-right: 50px;
}

.brand-logos{
  display: grid;
  grid-template-columns: repeat(3, 2fr);
  gap: 50px;
}

.brand-logos div{
  display: flex;
  justify-content: center;
}

.brand-logos img{
  width: 170px;
  height: 170px;
}

.client-recognition-btn{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.client-recognition-btn a{
  background-color: #d78129;
  color: #081b29;
  padding: 20px 40px;
  text-decoration: none;
  font-weight: 800;
  border: 3px double #081b29;
  border-radius: 10px;
  transition: all 0.5s ease-in-out;
}

.client-recognition-btn a:hover{
  background-color: #0071bc;
  color: #081b29;
  border-color: #0071bc;
  box-shadow: 0 0 10px #0071bc;
}


/*Client Testimonials Section Styling*/

.client-testimonials-section{
  margin-top: 100px;
}

.client-testimonials-section .testi-wrapper{
  background: url("/public/assets/lbop_bg.png") no-repeat center;
  background-size: cover;
  margin: 40px;
  padding: 40px;
  border-radius: 10px;
}

.client-testimonials-intro{
  text-align: center;
  margin: 0 50px 50px 50px;
}

.client-testimonials-intro p{
  text-align: center;
}

.client-testimonials{
  margin: 0 50px;
}

.client-images{
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.client-images img{
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin: 20px;
  opacity: 0.3;
  transition: all 0.3s ease-in-out;
}

.client-images img.active{
  width: 170px;
  height: 170px;
  border: 2px solid #0071bc;
  opacity: 1;
  margin: 10px;
}

.testimonial-content{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.testimonial-content h3{
  font-size: 25px;
  color: #081b29;
}

.testimonial-content h4{
  color: #d78129;
}

.testimonial-content p{
  margin: auto;
  text-align: center;
}

.mobile-testimonials{
  display: none;
}


/*Why Choose Us Section Styling*/

.why-choose-section{
  background: url("/public/assets/choose_bg.png") no-repeat center;
  background-size: cover;
  margin-top: 100px;
}

.why-choose-introduction{
  text-align: center;
  margin: 0 50px 50px 50px;
}

.why-choose-introduction p{
  text-align: center;
}

.why-choose-reasons{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 50px;
}

.reason-card{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 50px;
  margin: 0 20px;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.reason-card:hover{
  box-shadow: 0 0 10px #0071bc;
}

  /*Individual Card Styles*/

#light_orange{
  background: #e9bc77;
}

#dark_orange{
  background: #d78129;
}

#light_blue{
  background: #0071bc;
}

#dark_blue{
  background: #081b29;
  border: 1px solid #0071bc;
}

.reason-icon i{
  color: #081b29;
  font-size: 70px;
}

.reason-card h3{
  color: #081b29;
  font-size: 40px;
  margin-top: 20px;
}

.reason-card h4{
  color: #081b29;
  font-size: 20px;
  text-align: center;
}

.final-reason{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}

.final-reason .reason-card{
  padding: 20px 100px;
}


/*Lead Generation Section Styling*/

.lead-generation-section{
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.lead-gen-intro{
  margin-left: 50px;
  margin-right: 50px;
  margin-bottom: 50px;
  text-align: center;
}

.lead-gen-intro h2{
  color: #081b29;
}

.lead-gen-intro p{
  font-size: 15px;
  text-align: center;
}

.lead-gen-intro p a{
  color: #081b29;
  text-decoration: none;
  transition: all 300ms ease-in-out;
}

.lead-gen-intro p a:hover{
  color: #d78129;
}

.form-area{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 50px;
  margin-right: 50px;
}

.top-row{
  display: grid;
  grid-template-columns: repeat(3, 2fr);
  gap: 40px;
}

.inside label{
  color: #081b29;
  font-weight: 800;
}

.inside input, select{
  padding: 20px 40px;
  border-radius: 10px;
  border: none;
  background: #081b29;
  opacity: 0.8;
  text-align: left;
  outline: none;
  color:#0071bc;
}

.middle-row{
  display: grid;
  grid-template-columns: repeat(3, 2fr);
  gap: 40px;
}

.middle-row select{
  width: 240px;
  cursor: pointer;
}

.bottom-row .inside #message{
  padding: 20px 40px;
  border-radius: 10px;
  border: none;
  background: #081b29;
  text-align: left;
  width: 730px;
  outline: none;
  color:#0071bc;
  opacity: 0.7;
  resize: none;
}

.submit-btn{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 100px;
}

.submit-btn button{
  background-color: #d78129;
  color: #081b29;
  padding: 25px 70px;
  font-weight: 800;
  border: 1px solid #081b29;
  border-radius: 10px;
  cursor: pointer;
  transition: all 500ms ease-in-out;
}

.submit-btn button:hover{
  background-color: #0071bc;
  color: #081b29;
  border-color: #0071bc;
  box-shadow: 0 0 10px #0071bc;
}

/*Home Page Styling Ends*/


/*About Page Styling Begins*/

  /**About Introduction Section Styling**/

.about-introduction{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  align-items: center;
}

.about-intro-left{
  display: flex;
  flex-direction: column;
  max-width: 100%;
  margin-left: 50px;
  margin-top: 50px;
}

.about-intro-left h3{
  margin-bottom: 20px;
}

.about-intro-left h1{
  margin-bottom: 20px;
}

.about-intro-left h1 span{
  color: #0071bc;
}

.about-intro-left p{
  color: #d78129;
  margin-bottom: 40px;
}

.about-intro-left .about-intro-btn{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}

.about-intro-left a{
  background: #0071bc;
  color: #081b29;
  padding: 20px 40px;
  text-decoration: none;
  font-weight: 800;
  border-radius: 10px;
  transition: all 500ms ease-in-out;
}

.about-intro-left a:hover{
  background-color: #d78129;
  box-shadow: 0 0 5px #e9bc77;
}

.about-intro-right{
  display: flex;
  flex-direction: column;
  max-width: 100%;
  margin-right: 50px;
  margin-top: 10px;
}

.about-intro-right-img{
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.about-intro-right-img img{
  max-width: 700px;
  max-height: 700px;
  border-radius: 10px;
}

  /**About Detailed Section Styling**/

.about-detailed{
  background: url("/public/assets/aboutus_body_bg.png") no-repeat center;
  background-size: cover;
  margin-top: 100px;
}

.about-detailed-content{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 50px;
  margin-left: 50px;
  text-align: center;
}

.about-detailed-content h2, .about-detailed-content p{
  text-align: center;
}

.about-detailed-content p{
  margin-bottom: 40px;
}


  /**About Culture Section Styling**/

.about-culture{
  margin-top: 70px;
}

.about-culture-content{
  display: flex;
  flex-direction: column;
  gap: 50px;
  justify-content: center;
  align-items: center;
  margin-right: 50px;
  margin-left: 50px;
  text-align: center;
}

.about-culture-content .introduction h2,
.about-culture-content .introduction p{
  text-align: center;
}

.core-values{
  display: grid;
  grid-template-columns: repeat(3, 2fr);
  gap: 50px;
  margin-bottom: 70px;
}

.core-values .core-value{
  background: transparent;
  box-shadow: 0 0 10px #0071bc;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  padding-bottom: 100px;
  border-radius: 10px;
}

.core-values .core-value i{
  color: #d78129;
  font-size: 100px;
}

.core-values .core-value h3{
  font-size: 28px;
}

.core-values .core-value p{
  color: #d78129;
  font-size: 14px;
  line-height: 1.4;
  text-align: center;
}

  /**About Team Section Styling**/

.about-team{
  background-image: linear-gradient(#fff, #fff, #081b29);
}

.about-team-content{
  display: flex;
  flex-direction: column;
  gap: 50px;
  justify-content: center;
  align-items: center;
  margin-right: 50px;
  margin-left: 50px;
  margin-bottom: 100px;
  text-align: center;
}

.about-team-content h2,
.about-team-content p{
  text-align: center;
}

.about-team-content p{
  color: #081b29;
}

.team-members{
  display: grid;
  grid-template-columns: repeat(3, 2fr);
  gap: 50px;
}

.team-members .team-member{
  background: #081b29;
  box-shadow: 0 0 10px #0071bc;
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 20px;
  padding-bottom: 70px;
  border-radius: 10px;
}

.team-members .team-member img{
  width: 170px;
  height: 170px;
  border: 2px solid #0071bc;
  border-radius: 50%;
  margin: auto;
}

.team-members .team-member h3{
  color: #d78129;
}

.team-members .team-member p{
  color: #0071bc;
  font-size: 12px;
}

  /**About Lead Gen Mini Section Styling**/

.lead-gen-mini-main{
  background-image: linear-gradient(#0071bc, #0071bc, #081b29, #081b29);
  display: flex;
  flex-direction: column;
  gap: 100px;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 10px #0071bc;
  border-radius: 10px;
  margin-left: 50px;
  margin-right: 50px;
  margin-bottom: 100px;
  padding: 70px;
}

.lead-gen-mini-main .lead-gen-mini-heading{
  text-align: center;
}

.lead-gen-mini-main .lead-gen-mini-heading h2{
  color: #081b29;
}

.lead-gen-mini-main .lead-gen-mini-btn{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 70px;
}

.lead-gen-mini-main .lead-gen-mini-btn a{
  background: #d78129;
  color: #081b29;
  text-decoration: none;
  font-weight: 700;
  box-shadow: 0 0 5px #d78129;
  border-radius: 10px;
  padding: 20px 40px;
  transition: all 500ms ease-in-out;
}

.lead-gen-mini-main .lead-gen-mini-btn a:hover{
  color: #0071bc;
  box-shadow: 0 0 10px #d78129;
}

.lead-gen-mini-main .lead-gen-mini-btn a i{
  font-size: 25px;
  position: relative;
  top: 4px;
  left: 4px;
  transition: all 400ms ease-in-out;
}

.lead-gen-mini-main .lead-gen-mini-btn a:hover i{
  left: 7px;
}

/*About Page Styling Ends*/



/*Blog Page Styling Begins*/

.maintenance-container{
  background: url("/public/assets/choose_bg.png") no-repeat center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.maintenance-container .maintenance-content{
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
  margin: 100px 50px;
  box-shadow: 0 0 5px #081b29;
  padding: 40px;
  border-radius: 10px;
}

.maintenance-content .maintenance-icon{
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 5px #081b29;
  border-radius: 10px;
  padding: 20px;
}

.maintenance-content .maintenance-icon i{
  color: #0071bc;
  font-size: 150px;
  text-shadow: 0 0 10px #0071bc;
}

.maintenance-content .maintenance-text h1{
  color: #081b29;
  text-align: center;
}

.maintenance-content .maintenance-text p{
  text-align: center;
}

.maintenance-content .maintenance-btns {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.maintenance-content .maintenance-btns a,
.maintenance-content .maintenance-btns button {
  background: transparent;
  color: #d78129;
  text-decoration: none;
  font-weight: 700;
  padding: 20px 40px;
  box-shadow: 0 0 5px #081b29;
  border-radius: 10px;
  transition: all 400ms ease-in-out;
}

.maintenance-content .maintenance-btns button {
  border: none;
  outline: none;
  cursor: pointer;
}

.maintenance-content .maintenance-btns a:hover,
.maintenance-content .maintenance-btns button:hover {
  color: #081b29;
  box-shadow: 0 0 10px #081b29;
}

/*Blog Page Styling Ends*/



/*Industries Page Styling Begins*/

  /**Industries Introduction Section Styling**/

.industries-intro{
  background: url("/public/assets/industries_introbg.png") no-repeat;
  background-size: cover;
}

.industries-intro .left-section .img-container{
  display: flex;
  justify-content: center;
  align-items: center;
}

.industry-container .about-intro-left p{
  color: #fff;
}

.industries-intro .right-section .intro-headings h4{
  margin-top: 5px;
  margin-bottom: 50px;
}

.industries-intro .right-section .intro-headings h4 a{
  color: #d78129;
  text-decoration: none;
  transition: all 400ms ease-in-out;
}

.industries-intro .right-section .intro-headings h4 a:hover{
  color: #0071bc;
}

.industries-intro .right-section .intro-headings h3{
  margin-bottom: 50px;
}

  /**General Industries Styling**/

.industry-for-below{
  display: none;
}

.industry-container{
  margin-top: 70px;
}

.industry-container .industry-unordered{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin: 30px;
}

.industry-container .industry-unordered li{
  color: #d78129;
  transition: all 400ms ease-in-out;
}

.industry-container .industry-unordered li:hover{
  color: #0071bc;
}

.industry-container .about-intro-btn{
  margin-top: 50px;
}

.industry-container .right-section .about-intro-btn a{
  background: #d78129;
  color: #081b29;
  text-decoration: none;
  font-weight: 700;
  padding: 20px 40px;
  border-radius: 10px;
  transition: all 500ms ease-in-out;
}

.industry-container .right-section .about-intro-btn a:hover{
  background: #0071bc;
  box-shadow: 0 0 10px #0071bc;
}

  /**Circle Spin Animation**/

.industry-container .img-container span{
  color: #0071bc;
}

.industry-container .img-container{
  position: relative;
  width: 350px;
  height: 350px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.industry-container .img-container .circle-spin{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(0);
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border-top: 2px solid #081b29;
  border-bottom: 2px solid #081b29;
  border-left: 2px solid #0071bc;
  border-right: 2px solid #0071bc;
  animation: imgSpinner 8s linear infinite;
}

  /**Education Industry Styles**/

.edu-industry{
  background: url("/public/assets/education_bg.png") no-repeat;
  background-size: cover;
  margin-top: 0;
}

.edu-industry-right .edu-industry-right-img img{
  width: 300px;
  height: 300px;
 }

  /**Sports Industry Styles**/

.sports-industry{
  background: url("/public/assets/sports_bg.png") no-repeat;
  background-size: cover;
}

.sports-industry .img-container .circle-spin{
  border-left-color: #d78129;
  border-right-color: #d78129;
}

.sports-industry-left .img-container img{
  width: 300px;
  height: 300px;
}

.m-sports-industry .img-container .circle-spin{
  border-left-color: #d78129;
  border-right-color: #d78129;
}

.sports-industry-right .sports-industry-btn,
.ecommerce-industry-right .ecommerce-industry-btn,
.build-industry-right .build-industry-btn{
  display: flex;
  justify-content: center;
  align-items: center;
}

  /**Finance Industry Styles**/

.finance-industry{
  background: url("/public/assets/finance_bg.png") no-repeat;
  background-size: cover;
}

.finance-industry .img-container .circle-spin{
  border-left-color: #e9bc77;
  border-right-color: #e9bc77;
}

.finance-industry-right .img-container img{
  width: 300px;
  height: 300px;
}

  /**eCommerce Industry Styles**/

.ecommerce-industry{
  background: url("/public/assets/ecommerce_bg.png") no-repeat;
  background-size: cover;
}

.ecommerce-industry-left .img-container img{
  width: 300px;
  height: 300px;
}

  /**Media & Entertainment Industry Styles**/

.media-industry{
  background: url("/public/assets/media_bg.png") no-repeat;
  background-size: cover;
}

.media-industry .img-container .circle-spin{
  border-left-color: #d78129;
  border-right-color: #d78129;
}

.media-industry-right .img-container img{
  width: 300px;
  height: 300px;
}

  /**Building & Construction Industry Styles**/

.build-industry{
  background: url("/public/assets/building_bg.png") no-repeat;
  background-size: cover;
}

.build-industry .img-container .circle-spin{
  border-left-color: #e9bc77;
  border-right-color: #e9bc77;
}

.m-build-industry .img-container .circle-spin{
  border-left-color: #e9bc77;
  border-right-color: #e9bc77;
}

.build-industry-left .img-container img{
  width: 300px;
  height: 300px;
}

  /**Industry Lead Gen Mini Styles**/

.industries-lead-gen-main{
  background-image: linear-gradient(#0071bc, #081b29, #081b29);
  display: grid;
  grid-template-columns: 3fr 1fr 1fr;
  box-shadow: 0 0 5px #0071bc;
  border-radius: 10px;
  padding: 40px;
  margin: 100px 200px;
  cursor: pointer;
  transition: all 400ms ease-in-out;
}

.industries-lead-gen-main:hover{
  box-shadow: 0 0 10px #0071bc;
}

.industries-lead-gen-main .industries-lead-gen-heading{
  display: flex;
  justify-content: center;
  align-items: center;
}

.industries-lead-gen-main .industries-lead-gen-heading h3{
  color: #d78129;
  text-align: center;
  font-size: 20px;
}

.industries-lead-gen-main .industries-lead-gen-icon #down{
  display: none;
}

.industries-lead-gen-main .industries-lead-gen-icon i{
  color: #d78129;
  font-size: 150px;
  position: relative;
  left: 0;
  top: 5px;
  transition: all 400ms ease-in-out;
}

.industries-lead-gen-main:hover .industries-lead-gen-icon i{
  color: #0071bc;
  left: 7px;
}

.industries-lead-gen-main .industries-lead-gen-btn{
  display: flex;
  justify-content: center;
  align-items: center;
}

.industries-lead-gen-main .industries-lead-gen-btn a{
  background: transparent;
  color: #0071bc;
  text-decoration: none;
  font-weight: 700;
  box-shadow: 0 0 5px #0071bc;
  border-radius: 10px;
  padding: 70px 20px;
  transition: all 400ms ease-in-out;
}

.industries-lead-gen-main:hover .industries-lead-gen-btn a{
  box-shadow: 0 0 10px #0071bc;
}

.industries-lead-gen-main .industries-lead-gen-btn a:hover{
  color: #d78129;
  box-shadow: 0 0 15px #0071bc;
}

/*Industries Page Styling Ends*/


/*Built By Us Page Styling Begins*/

.built-by-us{
  background: url("/public/assets/builtbyus_bg.png") no-repeat center;
  background-size: cover;
  margin-bottom: 100px;
}

.built-by-us .built-by-us-intro{
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.built-by-us .built-by-us-intro :nth-child(1){
  margin-top: 20px;
}

.built-by-us .built-by-us-intro :nth-child(2){
  color: #081b29;
}

.built-by-us .built-by-us-intro :nth-child(3){
  margin-bottom: 20px;
}

.bbu-grid-area :nth-child(5) .project-description p,
.bbu-grid-area :nth-child(6) .project-description p,
.bbu-grid-area :nth-child(7) .project-description p,
.bbu-grid-area :nth-child(8) .project-description p{
  color: #fff;
}

.bbu-grid-area :nth-child(5) .project-description .proj-desc-btns a,
.bbu-grid-area :nth-child(6) .project-description .proj-desc-btns a,
.bbu-grid-area :nth-child(7) .project-description .proj-desc-btns a,
.bbu-grid-area :nth-child(8) .project-description .proj-desc-btns a{
  color: #d78129;
}

.bbu-grid-area :nth-child(5) .project-description .proj-desc-btns a:hover,
.bbu-grid-area :nth-child(6) .project-description .proj-desc-btns a:hover,
.bbu-grid-area :nth-child(7) .project-description .proj-desc-btns a:hover,
.bbu-grid-area :nth-child(8) .project-description .proj-desc-btns a:hover{
  color: #081b29;
}

.built-projects-grid{
  box-shadow: 0 0 5px #0071bc;
  border-radius: 10px;
}

.built-projects-grid .project-description{
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.built-projects-grid .project-description p{
  color: #081b29;
  text-align: center;
  font-size: 16px;
  line-height: 1.2;
  margin-top: 20px;
}

.project-description .proj-desc-btns{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 100px;
  margin-bottom: 20px;
}

.project-description .proj-desc-btns a{
  background: transparent;
  color: #081b29;
  text-decoration: none;
  font-size: 14px;
  padding: 20px 40px;
}

.project-description .proj-desc-btns :nth-child(1){
  box-shadow: 0 0 5px #d78129;
  border-radius: 10px;
  transition: all 500ms ease-in-out;
}

.project-description .proj-desc-btns :nth-child(1):hover{
  background: #d78129;
}

.project-description .proj-desc-btns :nth-child(2){
  box-shadow: 0 0 5px #0071bc;
  border-radius: 10px;
  transition: all 500ms ease-in-out;
}

.project-description .proj-desc-btns :nth-child(2):hover{
  background: #0071bc;
}


/*Built By Us Page Styling Ends*/


/*Hire A Developer Page Styling Begins*/

.hire-dev-intro{
  gap: 50px;
}

.hire-dev-left h4{
  position: relative;
  bottom: 50px;
  text-decoration: underline;
}

.hire-dev-left h4 a{
  background: none;
  color: #0071bc;
  padding: 0;
  transition: all 500ms ease-in-out;
}

.hire-dev-left h4 a:hover{
  background: none;
  color: #d78129;
  box-shadow: none;
}

.hire-dev-left .hire-dev-headings{
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.hire-dev-left .hire-dev-text p{
  color: #fff;
}

.hire-dev-right{
  justify-content: center;
  align-items: center;
}

.hire-dev-right .hire-dev-box{
  background-image: linear-gradient(#081b29, #081b29, #0071bc);
  padding: 20px 40px;
  box-shadow: 0 0 10px #0071bc;
  border-radius: 10px;
  position: relative;
  bottom: 70px;
}

.hire-dev-right .hire-dev-box .hire-dev-box-cont{
  display: grid;
  grid-template-columns: repeat(3, 2fr);
  gap: 40px;
}

.hire-dev-box-cont i{
  color: #d78129;
  font-size: 90px;
}

.hire-dev-box-cont h3{
  text-align: center;
}

.hire-dev-box-cont h3:nth-child(2){
  font-size: 24px;
}

.hire-dev-box-cont h3:nth-child(3){
  border-left: 2px solid #0071bc;
}

.hire-dev-box-cont h3:nth-child(3) #larger{
  color: #d78129;
  font-size: 38px;
  margin-left: 10px;
}

.hire-dev-box-cont h3:nth-child(3) #smaller{
  color: #d78129;
  font-size: 20px;
}

.hire-dev-box .hire-dev-btn{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.hire-dev-box .hire-dev-btn a{
  background: transparent;
  color: #d78129;
  text-decoration: none;
  box-shadow: 0 0 5px #081b29;
  border-radius: 10px;
  padding: 15px 30px;
  transition: all 500ms ease-in-out;
}

.hire-dev-box .hire-dev-btn a:hover{
  box-shadow: 0 0 10px #081b29;
}

.hire-dev-right .not-decided{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hire-dev-right .not-decided p{
  color: #d78129;
  text-align: center;
  font-size: 14px;
}

.hire-dev-right .not-decided a{
  color: #0071bc;
  text-decoration: none;
  padding-bottom: 5px;
  border-bottom: 1px solid #0071bc;
  transition: all 500ms ease-in-out;
}

.hire-dev-right .not-decided a:hover{
  color: #d78129;
}

/*Hire A Developer Page Styling Ends*/


/*Software Development Page Styling Begins*/

.software-dev-right .not-decided p{
  color: #d78129;
}

.services-technologies{
  background: url("/public/assets/servicetech_bg.gif") no-repeat;
  background-size: cover;
}

.services-technologies-intro{
  margin-top: 70px;
}

.services-technologies-intro p{
  color: #fff;
}

/*Software Development Page Styling Ends*/

/*Case Study Page Styling Begins*/

.case-study-intro .case-intro-left .case-intro-description p{
  color: #fff;
}

.case-study-intro .case-intro-right .case-intro-image{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.case-intro-right .case-intro-image img{
  max-width: 100%;
  max-height: 100%;
  border-radius: 10px;
}

.case-study-overview{
  background: #081b29;
}

.laptop-image-section{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 40px;
}

.laptop-image-section img{
  max-width: 100%;
  max-height: 100%;
}

.case-study-about, .case-study-features{
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  margin-right: 20px;
}

.case-study-features{
  margin-top: 40px;
}

.case-study-features ul{
  background: #0071bc;
  box-shadow: 0 0 5px #0071bc;
  border-radius: 10px;
  padding: 20px 100px;
  list-style: none;
}

.case-study-features ul li{
  color: #081b29;
  font-size: 20px;
  margin-bottom: 20px;
}

.case-study-features ul li i{
  color: #d78129;
  position: relative;
  right: 7px;
  top: 7px;
  font-size: 40px;
}

.case-study-process{
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}

.case-study-process .process-details{
  background-image: linear-gradient(#0071bc, #0071bc, #081b29);
  display: grid;
  grid-template-columns: repeat(3, 2fr);
  gap: 20px;
  padding: 20px;
  box-shadow: 0 0 5px #0071bc;
  border-radius: 10px;
}

.case-study-process .process-details .process-stage{
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.case-study-process .process-details .process-stage h3{
  color: #081b29;
  font-size: 24px;
  text-align: center;
}

.case-study-process .process-details .process-stage ul{
  list-style: none;
}

.case-study-process .process-details .process-stage ul li{
  color: #081b29;
  font-size: 20px;
  margin-bottom: 15px;
}

.case-study-process .process-details .process-stage ul li:last-child{
  color: #d78129;
}

.case-study-process .process-details .process-stage ul li i{
  color: #d78129;
  font-size: 40px;
  position: relative;
  top: 7px;
}

.case-study-challenges {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
  margin: 40px 20px 0 20px;
}

.mobile-image-section{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 40px;
}

.mobile-image-section img{
  max-width: 100%;
  max-height: 100%;
}

.case-study-solutions {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
  margin: 40px 20px 0 20px;
}

.tablet-image-section{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 40px;
}

.tablet-image-section img{
  max-width: 100%;
  max-height: 100%;
}

.case-study-impact{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 40px 20px 0 20px;
}

.case-study-tech-stack{
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
  margin: 100px;
  padding: 20px;
  box-shadow: 0 0 10px #0071bc;
  border-radius: 10px;
}

.case-study-tech-stack .tech-stack-cards{
  display: flex;
  gap: 40px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.case-study-tech-stack .tech-stack-cards .tech-stack-card{
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 5px #d78129;
  border-radius: 10px;
  width: 200px;
  height: 200px;
}

.case-study-tech-stack .tech-stack-cards .tech-stack-card img{
  width: 100px;
  height: 100px;
}

.case-study-tech-stack .tech-stack-cards .tech-stack-card h4{
  color: #d78129;
  font-size: 14px;
}

.case-study-cta{
  margin-top: 70px;
}

/*Case Study Page Styling Ends*/

/*Contact Page Styling Begins*/

.contact{
  background: url("/public/assets/contact_bg.png") no-repeat center;
  background-size: cover;
}

.contact-intro h2{
  color: #d78129;
}

.contact-form-area #contact_us .inside label{
  color: #d78129;
}

.contact-submit-btn{
  margin-bottom: 0;
}

.contact-client-testimonials{
  margin-top: 0;
}

.contact-btns{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 100px;
  margin-bottom: 50px;
}


#call_btn{
  background-color: #081b29;
  color: #d78129;
  padding: 20px 80px;
  font-weight: 800;
  border: 1px solid #d78129;
  border-radius: 10px;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}

#call_btn:hover{
background-color: #d78129;
color: #081b29;
box-shadow: 0 0 5px #e9bc77;
}

#wa_btn{
background-color: #075e54;
color: #fff;
padding: 20px 40px;
font-weight: 800;
border: 1px solid #fff;
border-radius: 10px;
transition: all 0.5s ease-in-out;
cursor: pointer;
}

#wa_btn:hover{
background-color: #25d366;
color: #081b29;
border-color: #25d366;
box-shadow: 0 0 5px #25d366;
}

#wa_btn i,
#call_btn i{
  position: relative;
  top: 2px;
  left: 4px;
  font-size: 20px;
}

.error-message{
  color: #ff0000;
  position: relative;
  left: 7px;
  font-size: 10px;
}

.success-message{
  color: #081b29;
  position: relative;
  top: 10px;
  font-size: 10px;
  animation: fadeIn 10s linear infinite;
}



/*Contact Page Styling Ends*/

/*Privacy Policy Page Styling Begins*/

.privacy-notice{
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
  margin: 50px;
}

.privacy-notice p{
  text-align: center;
  font-size: 18px;
  line-height: 1.1;
}

.privacy-notice h2{
  font-size: 24px;
  text-align: center;
}

.privacy-notice h3{
  font-size: 20px;
  color: #d78129;
  text-align: center;
}

.privacy-notice ul{
  color: #0071bc;
  line-height: 1.7;
  margin-left: 20px;
}

.privacy-notice ul li{
  margin-bottom: 10px;
}

.privacy-notice a{
  color: #0071bc;
  text-decoration: none;
  transition: all 400ms ease-in-out;
}

.privacy-notice a:hover{
  color: #d78129;
}

/*Privacy Policy Page Styling Ends*/


/*Footer Section Styling*/

.footer{
  display: grid;
  grid-template-columns: repeat(3, 2fr);
  gap: 50px;
  box-shadow: 0 2px 5px #0071bc;
}

.footer-left{
  display: flex;
  flex-direction: column;
  margin-left: 50px;
  margin-right: 50px;
}

.footer-left .footer-logo{
  display: flex;
  justify-content: left;
}

.footer-left .footer-logo img{
  width: 150px;
  height: 150px;
  margin-top: 30px;
}

.footer-left .footer-text p{
  color: #0071bc;
  text-align: justify;
  font-size: 12px;
  line-height: 1.5;
  margin-bottom: 40px;
}

.footer-subscribe .subscribe label{
  color: #d78129;
  font-size: 18px;
  margin-bottom: 40px;
}

#email_input{
  padding: 12px;
  background: #0071bc;
  border-radius: 10px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border: none;
  margin-top: 15px;
  text-align: left;
  outline: none;
  color: #081b29;
  box-shadow: 0 0 5px #0071bc;
}

#email_input::placeholder{
  color: #081b29;
}

#subscribe{
  padding: 12px;
  border-radius: 10px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border: none;
  font-weight: 700;
  background: transparent;
  box-shadow: 0 0 5px #0071bc;
  color: #0071bc;
  transition: all 400ms ease-in-out;
}

#subscribe:hover{
  box-shadow: 0 0 10px #0071bc;
  cursor: pointer;
}

.footer-success{
  color: #d78129;
  position: relative;
  top: 2px;
  left: 5px;
}

small{
  color: #0071bc;
  margin-left: 8px;
  font-size: 11px;
}

.footer-center{
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  text-align: center;
}

.footer-center h4{
  color: #d78129;
  font-size: 24px;
}

.footer-center ul{
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  list-style: none;
}

.footer-center ul li a{
  color: #0071bc;
  text-decoration: none;
  font-size: 12px;
  transition: all 400ms ease-in-out;
}

.footer-center ul li a:hover{
  color: #d78129;
}

.footer-right{
  display: flex;
  flex-direction: column;
  text-align: left;
  margin: 50px 50px 0 55px;
}

.footer-left .footer-right, .footer-center{
  margin-bottom: 50px;
}

.footer-right .connect h4{
  color: #d78129;
  font-size: 24px;
  text-align: left;
}

.footer-right .connect ul{
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  list-style: none;
}

.footer-right .connect ul li a{
  color: #0071bc;
  text-decoration: none;
  font-size: 12px;
  transition: all 300ms ease-in-out;
}

.footer-right .connect ul li a:hover{
  color: #d78129;
}

.footer-right .connect ul li a i{
  position: relative;
  top: 4px;
  font-size: 30px;
}

.footer-socials{
  margin-top: 75px;
}

.footer-socials a i{
  background: transparent;
  color: #0071bc;
  font-size: 35px;
  border: 1px solid #0071bc;
  border-radius: 5px;
  padding: 2px;
  margin-right: 20px;
  opacity: 0.7;
  box-shadow: 0 0 10px #0071bc;
  transition: all 400ms ease-in-out;
}

.footer-socials a i:hover{
  opacity: 1;
  box-shadow: none;
}

.footer-lower{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 700px;
}

.footer-lower .copyright, .footer-lower .terms-policy{
  color: #0071bc;
  font-size: 15px;
  font-weight: 600;
}

.footer-lower .terms-policy a{
  color: #0071bc;
  text-decoration: none;
  transition: all 400ms ease-in-out;
}

.footer-lower .terms-policy a:hover{
  color: #d78129;
}

.footer-lower .copyright i{
  position: relative;
  top: 3px;
  font-size: 16px;
}

.scroll-to-top{
  position: fixed;
  bottom: 30px;
  left: 10px;
  z-index: 1000;
}

.scroll-to-top button{
  background: transparent;
  color: #d78129;
  border: none;
  box-shadow: 0 0 5px #d78129;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  padding: 5px;
  cursor: pointer;
  transition: all 200ms ease-in-out;
}

.scroll-to-top button:hover{
  box-shadow: 0 0 10px #d78129;
}

.scroll-to-top i{
  font-size: 20px;
}


/*Responsive Design BREAKPOINTS*/



/*MAX w 1200*/

@media (max-width: 1200px){

  /*General*/

  h1{
    font-size: 28px;
  }

  h2{
    font-size: 24px;
  }

  p{
    font-size: 18px;
  }

  /*Header*/

  #menu_icon{
    display: block;
  }

  #main_nav{
    position: absolute;
    top: 100%;
    left: -100%;
    width: 100%;
    padding: 0;
    transition: all 400ms ease-in-out;
    transition-delay: 25ms;
  }

  #main_nav.active{
    left: 0;
    transition-delay: 0s;
  }

  #main_nav ul {
    background: #081b29;
    display: block;
    max-width: fit-content;
    padding: 40px;
    padding-right: 100px;
    padding-bottom: 100px;
    border-radius: 5px;
    box-shadow: 0 0 5px #0071bc;
    align-items: center;
    cursor: pointer;
  }
  
  #main_nav ul li {
    margin: 0;
    position: relative;
    margin-bottom: 40px;
    font-size: 20px;
  }

  #main_nav ul li span{
    font-size: 25px;
    top: 5px;
  }

  .tohide-rotating-text{
    display: none;
  }

  #main_nav ul ul{
    display: none;
    position: absolute;
    top: -55px;
    left: 100%;
    background: url("/public/assets/sc_lphone_fourg.png") no-repeat;
    padding: 10px;
    border-radius: 5px;
  }

  #main_nav ul li:hover > ul {
    display: block;
  }

  #main_nav ul li:hover > ul li{
    font-size: 15px;
  }

  #main_nav ul li:hover > ul li .sub-side{
    font-size: 25px;
  }

  /*Introduction*/

  #dev_team,
  #more_about{
    padding: 15px 30px;
    white-space: nowrap;
  }

  /*Project Process*/


  /*Service Overview*/

  .service{
    padding: 20px;
  }

  .service-icon span{
    font-size: 70px;
  }

  .service h3{
    font-size: 18px;
  }

  .service p{
    font-size: 11px;
  }

  .service-btn button{
    padding: 15px 30px;
    font-weight: 700;
    border-width: 2px;
  }

  /*Technologies*/

  .technology-image img{
    width: 100px;
    height: 100px;
  }

  /*Recent Projects*/


  /*Client Recognition*/

  .client-recognition-left img{
    width: 400px;
    height: 400px;
  }

  .brand-logos img{
    width: 140px;
    height: 140px;
  }

  /*Client Testimonials*/

  .client-images img{
    width: 120px;
    height: 120px;
  }
  
  .client-images img.active{
    width: 130px;
    height: 130px;
  }

  .testimonial-content h3{
    font-size: 16px;
  }

  .testimonial-content h4{
    font-size: 12px;
  }

  .testimonial-content p{
    font-size: 14px;
    line-height: 1.2;

  }

  /*Why Choose*/

  .reason-card h3{
    font-size: 28px;
  }

  .reason-card h4{
    font-size: 18px;
  }

  /*Lead Generation*/

  .lead-generation-section{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .lead-gen-intro{
    margin: 0 50px 50px 50px;
    text-align: center;
  }

  .lead-gen-intro p{
    text-align: center;
  }

  /*Footer*/

  .footer{
    gap: 20px;
  }

  .footer-left{
    margin-left: 20px;
    margin-right: 20px;
  }

  .footer-left .footer-logo img{
    width: 130px;
    height: 130px;
  }

  .footer-left .footer-text p{
    font-size: 11px;
    line-height: 1.3;
  }

  .footer-subscribe .subscribe label{
    font-size: 17px;
  }

  #email_input{
    padding: 10px;
    background: #0071bc;
    border-radius: 10px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border: none;
    margin-top: 15px;
    text-align: left;
    outline: none;
    color: #081b29;
    box-shadow: 0 0 5px #0071bc;
  }

  #subscribe{
    padding: 9.5px;
    font-weight: 500;
  }

  small{ 
    font-size: 10px;
  }

  .footer-center{
    gap: 40px;
    margin-left: 15px;
  }

  .footer-center .center-left h4,
  .center-right h4{
    font-size: 20px;
  }

  .footer-center .center-left ul,
  .center-right ul{
    row-gap: 7px;
  }

  .footer-center .center-left ul li a,
  .center-right ul li a{
    font-size: 11px;
  }

  .footer-right{
    margin-left: 70px;
  }

  .footer-right .connect h4{
    font-size: 20px;
  }

  .footer-right .connect ul li a{
    font-size: 11px;
  }

  .footer-socials{
    margin-top: 60px;
  }

  .footer-socials a i{
    font-size: 30px;
    padding: 1px;
    margin-right: 15px;
  }

  .footer-lower{
    gap: 500px;
  }

  .footer-lower .copyright,
  .footer-lower .terms-policy{
    font-size: 13px;
  }
}

/*MAX w 991*/

@media (max-width: 991px){

  /*General*/
  h1{
    font-size: 24px;
  }

  h2{
    font-size: 20px;
  }

  p{
    font-size: 14px;
  }

  /*Header*/

  .header{
    padding: 10px;
  }

  .logo{
    margin-left: 20px;
  }

  .logo img{
    max-width: 100px;
    min-height: 100px;
  }

  #menu_icon{
    font-size: 40px;
  }

  .cta-button{
    margin-right: 20px;
  }

  .cta-button a{
    padding: 15px;
  }

  /*Introduction*/

  .introduction-container{
    background-image: url("/public/assets/sc_tabpad.png");
    gap: 15px;
  }

  .img-container img{
    width: 310px;
    height: 310px;
  }

  .intro-buttons{
    padding: 0;
  }

  #dev_team,
  #more_about{
    padding: 10px 25px;
    border-width: 2px;
  }

  /*Project Process*/

  

  .processIcons{
    font-size: 34px;
  }

  /*Service Overview*/

  .service{
    padding: 5px;
    border-width: 1.5px;
  }

  .service-icon span{
    font-size: 40px;
  }

  .service h3{
    font-size: 14px;
  }

  .service p{
    font-size: 10px;
    line-height: 1.2;
  }

  .service-btn button{
    padding: 10px 25px;
    border: none;
  }

  /*Technologies*/

  .technologies-nav li {
    margin: 0;
  }
  
  .technologies-nav a {
    font-size: 14px;
  }

  .technology-image img{
    width: 70px;
    height: 70px;
  }

  .technology-image p{
    font-size: 12px;
  }

  /*Client Recognition*/

  .client-recognition-section{
   display: flex;
   flex-direction: column;
  }
  
  .client-recognition-left{
    background: none;
    max-width: 100%;
    margin-left: 50px;
    margin-right: 50px;
    text-align: center;
  }
  
  .client-recognition-left p{
    text-align: center;
    margin-bottom: 20px;
  }

  .client-recognition-left img{
    display: none;
  }

  .client-recognition-right{
    margin-left: 20px;
    margin-right: 20px;
  }

  .brand-logos{
    display: grid;
    grid-template-columns: repeat(3, 2fr);
    gap: 20px;
  }

  .brand-logos img{
    width: 150px;
    height: 150px;
  }
  
  .client-recognition-btn a{
    padding: 15px 30px;
    border-width: 2px;
  }
  
  .client-recognition-btn a:hover{
    background-color: #0071bc;
    color: #081b29;
  }

  /*Client Testimonials*/

  .client-testimonials-section .testi-wrapper{
    background: none;
  }

  .client-testimonials .testimonial-content h3{
    color: #0071bc;
  }

  /*Why Choose*/

  .why-choose-reasons{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
  
  .reason-card{
    padding: 15px 45px;
    margin: 0;
  }

  .final-reason .reason-card{
    padding: 20px 90px;
  }

  /*Lead Generation*/

  .lead-gen-right{
    margin-right: 20px;
    margin-left: 20px;
  }

  .lead-gen-right form .input-box .submit-btn button{
    border-width: 2px;
  }


  /*About Page Responsive Styling Begins Here*/

    /**About Introduction**/

  .about-introduction{
    gap: 15px;
  }
  
  .about-intro-left{
    margin-left: 30px;
  }
  
  .about-intro-left a{
    padding: 15px 35px;
  }
  
  .about-intro-right{
    margin-right: 30px;
  }

  /**About Detailed**/
  
  .about-detailed-content{
    margin-right: 30px;
    margin-left: 30px;
  }

  /**About Culture**/

  .about-culture-content{
    gap: 20px;
    margin-right: 30px;
    margin-left: 30px;
  }

  .core-values{
    gap: 20px;
  }

  .core-values .core-value{
    box-shadow: 0 0 5px #0071bc;
    gap: 10px;
    padding-bottom: 50px;
  }

  .core-values .core-value i{
    font-size: 70px;
  }

  .core-values .core-value h3{
    font-size: 22px;
  }

  .core-values .core-value p{
    font-size: 12px;
    line-height: 1.3;
  }

    /**About Team**/

  .about-team-content{
    gap: 20px;
    margin-right: 30px;
    margin-left: 30px;
  }

  .team-members{
    gap: 20px;
  }

  .team-members .team-member{
    box-shadow: 0 0 5px #0071bc;
    gap: 10px;
    padding: 10px;
    padding-bottom: 50px;
  }

  .team-members .team-member img{
    width: 150px;
    height: 150px;
  }

  .team-members .team-member h3{
    font-size: 16px;
  }

  .team-members .team-member p{
    font-size: 14px;
  }

/*About Page Responsive Styling Ends Here*/


/*Industries Page Responsive Styling Begins Here*/

  .industry-container .industry-unordered{
    margin: 20px;
  }

  .industry-container .industry-unordered li{
    font-size: 12px;
  }


  .industry-container .right-section .about-intro-btn a{
    padding: 15px 25px;
    box-shadow: 0 0 5px #0071bc;
  }

/*Industries Page Responsive Styling Ends Here*/

/*Built By Us Page Responsive Styling Begins Here*/


  .built-by-us .recent-projects-intro{
    gap: 10px;
  }

  .built-by-us .recent-projects-intro :nth-child(1){
    margin-top: 10px;
  }

  .built-by-us .recent-projects-intro :nth-child(3){
    margin-bottom: 10px;
  }

  .built-projects-grid{
    box-shadow: 0 0 5px #0071bc;
    border-radius: 10px;
  }

  .built-projects-grid .project-description{
    gap: 10px;
  }

  .built-projects-grid .project-description p{
    font-size: 14px;
  }

  .project-description .proj-desc-btns{
    gap: 50px;
  }

  .project-description .proj-desc-btns a{
    font-size: 12px;
    padding: 15px 25px;
  }


/*Built By Us Page Responsive Styling Ends Here*/


/*Hire A Developer Page Responsive Styling Begins Here*/

.hire-dev-right .hire-dev-box .hire-dev-box-cont{
  display: flex;
  flex-direction: column;
  gap: 20px;
}

  .hire-dev-intro{
    gap: 20px;
  }

  .hire-dev-right .hire-dev-box{
    padding: 20px 50px;
    bottom: 0;
  }

  .hire-dev-box-cont .stack-icon{
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .hire-dev-box-cont h3:nth-child(2){
    font-size: 22px;
  }

  .hire-dev-box-cont h3:nth-child(3){
    border-left: none;
    border-top: 2px solid #0071bc;
    padding-top: 10px;
  }

  .hire-dev-box-cont h3:nth-child(3) span{
    margin-left: 0;
  }


  .hire-dev-right .not-decided{
   margin-top: 20px;
  }

  .hire-dev-right .not-decided p{
    font-size: 12px;
  }

/*Hire A Developer Page Responsive Styling Ends Here*/

/*Case Study Page Responsive Styling Begins Here*/

.case-study-process .process-details{
  gap: 10px;
  padding: 5px;
}

.case-study-process .process-details .process-stage h3{
  font-size: 20px;
}

.case-study-process .process-details .process-stage ul li{
  font-size: 16px;
  margin-bottom: 10px;
}

.case-study-process .process-details .process-stage ul li i{
  font-size: 30px;
}

/*Case Study Page Responsive Styling Ends Here*/

/*Indstries Page Responsive Styling Begins Here*/

  /**Industry Lead Gen Mini Responsive Styles**/

  .industries-lead-gen-main{
    padding: 20px;
    margin: 100px;
  }

  .industries-lead-gen-main .industries-lead-gen-heading h3{
    font-size: 18px;
  }

  .industries-lead-gen-main .industries-lead-gen-btn{
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .industries-lead-gen-main .industries-lead-gen-btn a{
    padding: 70px 40px;
  }

  /*Industries Page Responsive Styling Ends Here*/


  /*Footer*/

  .footer{
    gap: 10px;
  }

  .footer-left{
    margin-left: 10px;
    margin-right: 20px;
  }

  .footer-left .footer-logo img{
    width: 110px;
    height: 110px;
  }

  .footer-left .footer-text p{
    font-size: 10px;
    line-height: 1.2;
  }

  .footer-subscribe .subscribe label{
    font-size: 14px;
  }

  #email_input{
    padding: 7.5px 7px;
    border-radius: 7px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    font-size: 12px;
  }

  #subscribe{
    padding: 7px;
    font-size: 12px;
  }

  .footer-center{
    gap: 20px;
    margin-left: 10px;
  }

  .footer-center .center-left h4,
  .center-right h4{
    font-size: 18px;
    white-space: nowrap;
  }

  .footer-center .center-left ul,
  .center-right ul{
    row-gap: 5px;
  }

  .footer-center .center-left ul li a,
  .center-right ul li a{
    font-size: 10px;
  }

  .footer-right .connect h4{
    font-size: 18px;
    white-space: nowrap;
  }

  .footer-right .connect ul li a{
    font-size: 10px;
  }

  .footer-socials{
    margin-top: 35px;
  }

  .footer-socials a i{
    font-size: 28px;
    margin-right: 7px;
  }

  .footer-lower{
    gap: 400px;
  }

  .footer-lower .copyright,
  .footer-lower .terms-policy{
    font-size: 12px;
  }
}

/*MAX w 814 && MIN w 769*/

@media only screen and (max-width: 814px) and (min-width: 769px) {

  /*Home Introduction*/

  .introduction-container{
    gap: 5px;
  }

  .img-container img{
    width: 350px;
    height: 350px;
  }

  .intro-buttons{
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: center;
    align-items: center;
  }
 
  #dev_team,
  #more_about{
    padding: 15px 20px;
    border-width: 2px;
  }

  #dev_team{
    margin-right: 0;
  }

  /*Lead Generation*/

  .lead-gen-intro h2{
    font-size: 24px;
  }

  .lead-gen-intro p{
    font-size: 14px;
  }

  .form-area{
    margin-left: 20px;
    margin-right: 20px;
  }

  #contact_us{
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;
  }
  
  .top-row{
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .inside input, .inside select{
    width: 500px;
  }
  
  .middle-row{
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .middle-row select{
    width: 580px;
  }
  
  .bottom-row .inside #message{
    width: 500px;
  }
}

/*MAX w 990 && MIN w 521*/

@media only screen and (max-width: 990px) and (min-width: 521px){

  .contact-client-testimonials p{
    color: #081b29;
  }

  .contact-client-testimonials h4{
    color: #0071bc;
  }
}


/*MAX w 768*/

@media (max-width: 768px){

  /*General*/

  h1{
    font-size: 22px;
  }

  h2{
    font-size: 18px;
  }

  p{
    font-size: 12px;
  }

  /*Header*/

  .header{
    padding: 5px;
  }

  .logo{
    margin-left: 20px;
  }

  .logo img{
    max-width: 80px;
    min-height: 80px;
  }

  #menu_icon{
    font-size: 34px;
  }

  #main_nav ul {
    padding: 20px 50px 50px 20px;
  }
  
  #main_nav ul li {
    margin-bottom: 25px;
    font-size: 14px;
  }

  #main_nav ul li span{
    font-size: 19px;
    top: 5px;
  }

  #main_nav ul ul{
    top: -40px;
  }

  #main_nav ul li:hover > ul li{
    font-size: 11px;
  }

  #main_nav ul li:hover > ul li .sub-side{
    font-size: 21px;
  }

  .cta-button{
    margin-right: 20px;
  }

  .cta-button a{
    padding: 10px;
    font-size: 14px;
  }

  /*Introduction*/

  .introduction-container{
    background: url("/public/assets/sc_lphonetab.png") no-repeat center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0;
  }

  .introduction-container .left-section{
    margin: 0;
  }

  .img-container img{
    width: 400px;
    height: 400px;
  }

  .right-section {
    max-width: 100%;
    margin: auto;
  }

  .right-section h1 {
    text-align: center;
  }
  
  .intro-paragraph p{
    margin: 10px 30px;
    text-align: center;
  }

  .intro-buttons{
    justify-content: center;
    gap: 40px;
    padding: 0;
    font-size: 14px;
  }

  #dev_team,
  #more_about{
    padding: 10px 20px;
  }

  /*Project Process*/

  .large-gradientbg{
    background: url("/public/assets/sc_lphone_fourg.png") no-repeat center;
    background-size: cover;
  }

  /*Service Overview*/

  .s-overview-main{
    margin-top: 70px;
  }

  .s-overview-intro{
    margin: 0 20px 50px 20px;
  }

  
  .services-overview{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .service{
    padding: 0;
    border-width: 1.2px;
  }

  .service-icon span{
    font-size: 35px;
    padding: 25px;
    margin-top: 20px;
  }

  .service h3{
    font-size: 12px;
  }

  .service-btn{
    max-width: fit-content;
    margin: 20px auto;
  }

  .service-btn button{
    padding: 5px 15px;
    font-size: 11px;
    border: none;
    font-weight: 700;
  }

  .bottom-mover{
    margin-bottom: 70px;
  }

  /*Technologies*/

  .technologies-section {
    background: url("/public/assets/scmobile_alive_cog.gif") no-repeat center;
    background-size: cover;
  }

  .technologies-nav a {
    font-size: 11px;
  }

  .technologies-images{
    row-gap: 10px;
  }

  .technology-image img{
    width: 50px;
    height: 50px;
  }

  .technology-image p{
    font-size: 10px;
  }

  /*Recent Projects*/

  .recent-projects-intro {
    margin: 0 20px 50px 20px;
  }
  
  .rp-grid-area {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 0 50px;
  }

  /*Client Recognition*/

   .client-recognition-left{
     margin-left: 20px;
     margin-right: 20px;
   }
   
   .client-recognition-left p{
     margin-bottom: 10px;
   }
 
   .brand-logos{
     gap: 30px;
   }
 
   .brand-logos img{
     width: 120px;
     height: 120px;
   }
   
   .client-recognition-btn a{
    font-size: 14px;
     padding: 10px 25px;
     border: none;
   }

   /*Client Testimonials*/

   .client-testimonials-section{
    margin-top: 70px;
  }
  
  .client-testimonials-intro{
    text-align: center;
    margin: 0 20px 50px 20px;
  }
  
  .client-testimonials{
    margin: 0;
  }

   .client-images img{
    width: 80px;
    height: 80px;
  }
  
  .client-images img.active{
    width: 90px;
    height: 90px;
  }

  .testimonial-content h3{
    font-size: 14px;
  }

  .testimonial-content p{
    font-size: 12px;
    line-height: 1.1;
  }

  /*Why Choose*/

  .why-choose-section{
    margin-top: 70px;
  }

  .why-choose-introduction{
    text-align: center;
    margin: 0 20px 50px 20px;
  }

  /*Lead Generation*/

  .lead-gen-intro h2{
    font-size: 24px;
  }

  .lead-gen-intro p{
    font-size: 14px;
  }

  .form-area{
    margin-left: 20px;
    margin-right: 20px;
  }

  #contact_us{
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;
  }
  
  .top-row{
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .inside input, .inside select{
    width: 500px;
  }
  
  .middle-row{
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .middle-row select{
    width: 580px;
  }
  
  .bottom-row .inside #message{
    width: 500px;
  }


  /*About Page Responsive Styling Begins Here*/

    /**About Introduction**/

    .about-introduction{
      display: flex;
      flex-direction: column;
      gap: 0;
    }
    
    .about-intro-left{
      margin-left: 20px;
      margin-right: 20px;
      margin-bottom: 40px;
      align-items: center;
    }

    .about-intro-left h3,
    .about-intro-left h1,
    .about-intro-left p{
      text-align: center;
    }
    
    .about-intro-left a{
      font-size: 14px;
      padding: 20px;
    }
    
    .about-intro-right{
      margin-right: 20px;
      margin-left: 20px;
    }

    /**About Detailed**/

    .about-detailed{
      margin-top: 70px;
    }

    .about-detailed-content{
      margin-right: 20px;
      margin-left: 20px;
    }

    /**About Culture**/


    .about-culture{
      margin-top: 50px;
    }

    .about-culture-content{
      margin-right: 20px;
      margin-left: 20px;
    }

    .core-values{
      display: flex;
      flex-direction: column;
    }

    .core-values .core-value{
      padding-bottom: 70px;
    }

    #color_diff{
      color: #081b29;
    }

    /**About Team**/

    .about-team-content{
      margin-right: 20px;
      margin-left: 20px;
    }

    .team-members{
      display: grid;
      grid-template-columns: repeat(3, 2fr);
    }

    .team-members .team-member{
      gap: 5px;
      padding-bottom: 30px;
    }

    .team-members .team-member img{
      width: 120px;
      height: 120px;
    }

    .team-members .team-member h3{
      font-size: 14px;
    }

    .team-members .team-member p{
      font-size: 12px;
    }

    /**About Lead Gen Mini**/

    .lead-gen-mini-main{
      gap: 70px;
      margin-left: 30px;
      margin-right: 30px;
      padding-top: 50px;
      padding-bottom: 50px;
    }
    
    .lead-gen-mini-main .lead-gen-mini-btn a{
      font-weight: 600;
      padding: 15px 30px;
      font-size: 14px;
    }
    
    .lead-gen-mini-main .lead-gen-mini-btn a i{
      font-size: 20px;
      top: 3px;
    }


/*About Page Responsive Styling Ends Here*/



/*Blog Page Responsive Styling Begins Here*/

.maintenance-container .maintenance-content{
  padding: 30px;
}

.maintenance-content .maintenance-icon{
  padding: 15px;
}

.maintenance-content .maintenance-icon i{
  font-size: 120px;
}

.maintenance-content .maintenance-btns{
  display: flex;
  justify-content: center;
  align-items: center;
}

.maintenance-content .maintenance-btns a,
.maintenance-content .maintenance-btns button {
  padding: 15px 30px;
  font-size: 14px;
}

/*Blog Page Responsive Styling Ends Here*/



/*Industries Page Responsive Styling Begins Here*/

    .industry-for-above{
      display: none;
    }

    .industries-intro .right-section .intro-headings h4{
      display: none;
    }
    
    .industries-intro .right-section .intro-headings h3{
      margin-bottom: 20px;
      text-align: center;
    }

    .industries-intro .right-section .intro-headings h2{
      text-align: center;
    }

    .industry-container .industry-unordered{
      column-gap: 50px;
      margin: 20px;
    }
    
    .industry-container .industry-unordered li{
      font-size: 11px;
    }
    
    .industry-container .about-intro-btn a{
      background: #d78129;
      color: #081b29;
      text-decoration: none;
      font-size: 11px;
      padding: 10px 20px;
      border-radius: 10px;
      transition: all 500ms ease-in-out;
    }
    
    .industry-container .about-intro-btn a:hover{
      background: #0071bc;
      box-shadow: 0 0 10px #0071bc;
    }

    .edu-industry{
      background: url("/public/assets/education_bg_sclpt.png") no-repeat center;
      background-size: cover;
    }

    .m-sports-industry{
      background: url("/public/assets/sports_bg_sclpt.png") no-repeat center;
      background-size: cover;
    }

    .m-sports-industry-right-img img{
      width: 300px;
      height: 300px;
    }

    .finance-industry{
      background: url("/public/assets/finance_bg_sclpt.png") no-repeat center;
      background-size: cover;
    }

    .m-ecommerce-industry{
      background: url("/public/assets/ecommerce_bg_sclpt.png") no-repeat center;
      background-size: cover;
    }

    .m-ecommerce-industry-right-img img{
      width: 300px;
      height: 300px;
    }

    .media-industry{
      background: url("/public/assets/media_bg_sclpt.png") no-repeat center;
      background-size: cover;
    }

    .m-build-industry{
      background: url("/public/assets/building_bg_sclpt.png") no-repeat center;
      background-size: cover;
    }

    .m-build-industry-right-img img{
      width: 300px;
      height: 300px;
    }


  /**Industry Lead Gen Mini Responsive Styles**/

  .industries-lead-gen-main{
    padding: 20px 10px;
    margin: 50px;
  }

  .industries-lead-gen-main .industries-lead-gen-icon i{
    font-size: 130px;
  }

  .industries-lead-gen-main .industries-lead-gen-btn a{
    padding: 50px;
  }


/*Industries Page Responsive Styling Ends Here*/


/*Built By Us Page Responsive Styling Begins Here*/

  .built-by-us{
    background: url("/public/assets/builtbyus_bg_sclpt.png") no-repeat center;
    background-size: cover;
  }

  .built-by-us .recent-projects-intro{
    gap: 5px;
  }

  .built-by-us .recent-projects-intro :nth-child(1){
    font-size: 16px;
    margin-top: 10px;
  }

  .built-by-us .recent-projects-intro :nth-child(3){
    font-size: 16px;
    margin-bottom: 5px;
  }

  .built-projects-grid .project-description{
    gap: 20px;
  }

  .built-projects-grid .project-description p{
    font-size: 12px;
  }

  .project-description .proj-desc-btns a{
    padding: 10px 20px;
  }


/*Built By Us Page Responsive Styling Ends Here*/


/*Hire A Developer Page Responsive Styling Begins Here*/

  .hire-dev-left h4 a{
    font-size: 16px;
  }

  .hire-dev-left .hire-dev-headings{
    gap: 5px;
  }

  .hire-dev-right .hire-dev-box{
    padding: 20px 40px;
    bottom: 0;
  }

  .hire-dev-right .hire-dev-box .hire-dev-box-cont{
    display: grid;
    grid-template-columns: repeat(3, 2fr);
    gap: 40px;
  }

  .hire-dev-box-cont h3:nth-child(2){
    font-size: 24px;
  }

  .hire-dev-box-cont h3:nth-child(3){
    border-top: none;
    border-left: 2px solid #0071bc;
  }

  .hire-dev-box-cont h3:nth-child(3) #larger{
    margin-left: 10px;
  }

/*Hire A Developer Page Responsive Styling Ends Here*/

/*Case Study Page Responsive Styling Begins*/

  .case-study-intro .case-intro-left{
    margin-bottom: 20px;
  }

  .case-study-intro .case-intro-right .case-intro-image{
    margin-top: 0;
  }

  .case-study-process .process-details{
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 10px 100px;
  }

  .case-study-process .process-details .process-stage{
    gap: 10px;
  }

  .case-study-process .process-details .process-stage h3{
    font-size: 20px;
  }

  .case-study-process .process-details .process-stage ul li{
    font-size: 18px;
    margin-bottom: 10px;
  }

  .case-study-process .process-details .process-stage ul li i{
    font-size: 30px;
  }

  .case-study-tech-stack{
    margin: 70px 20px 0 20px;
    padding: 15px;
  }
  
  .case-study-tech-stack .tech-stack-cards{
    gap: 30px;
  }

  .case-study-tech-stack .tech-stack-cards .tech-stack-card{
    width: 170px;
    height: 170px;
  }

  .case-study-tech-stack .tech-stack-cards .tech-stack-card img{
    width: 70px;
    height: 70px;
  }

  .case-study-tech-stack .tech-stack-cards .tech-stack-card h4{
    font-size: 12px;
  }

/*Case Study Page Responsive Styling Ends*/


/*Services Pages Responsive Styling Begins Here*/

  .services-technologies{
    background: url("/public/assets/servicetech_bg.gif") no-repeat;
    background-size: cover;
  }

/*Services Pages Responsive Styling Ends Here*/


  /*Footer*/

  .footer{
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .footer-left{
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    margin-right: 10px;
  }

  .footer-left .footer-logo img{
    width: 150px;
    height: 150px;
  }

  .footer-left .footer-text p{
    font-size: 12px;
    text-align: center;
    line-height: 1.2;
  }

  .footer-subscribe{
    max-width: 500px;
    text-align: center;
  }

  .footer-subscribe .subscribe label{
    font-size: 18px;
  }

  #email_input{
    padding: 15px 50px;
    border-radius: 10px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    font-size: 14px;
  }

  #subscribe{
    padding: 15px 40px;
    font-size: 14px;
  }

  .footer-center{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-top: 20px;
  }

  .footer-center .center-left h4,
  .center-right h4{
    font-size: 22px;
  }

  .footer-center .center-left ul li a,
  .center-right ul li a{
    font-size: 12px;
  }

  .footer-right{
    margin: 0;
    margin-bottom: 20px;
    align-items: center;
  }

  .footer-right .connect h4{
    font-size: 22px;
    text-align: center;
  }

  .footer-right .connect ul li a{
    font-size: 12px;
  }

  .footer-socials{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
  }

  .footer-socials a i{
    font-size: 34px;
    padding: 5px;
    margin: 0;
  }

  .footer-lower{
    gap: 300px;
  }

/*Contact Page Responsive Styling Begins*/

  .contact-btns{
    gap: 70px;
    margin-bottom: 70px;
  }


  #call_btn{
    font-size: 12px;
    padding: 20px 50px;
  }


  #wa_btn{
    font-size: 12px;
    padding: 20px 10px;
  }

  #wa_btn i,
  #call_btn i{
    top: 4px;
  }

/*Contact Page Responsive Styling Ends*/

}



/*MAX w 600 && MIN w 521*/

@media only screen and (max-width: 600px) and (min-width: 521px){

  .inside input, .inside select{
    width: 400px;
  }
  
  .middle-row{
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .middle-row select{
    width: 480px;
  }
  
  .bottom-row .inside #message{
    width: 400px;
  }
}

/*MAX w 520*/

@media (max-width: 520px){

  /*General*/

  p{
    line-height: 1.5;
  }

  /*Header*/

  .header{
    padding: 0;
  }

  .logo img{
    max-width: 70px;
    min-height: 70px;
  }

  #menu_icon{
    font-size: 32px;
  }

  #main_nav ul {
    padding: 10px 30px 30px 10px;
  }
  
  #main_nav ul li {
    font-size: 12px;
  }

  #main_nav ul li span{
    font-size: 17px;
  }

  #main_nav ul li:hover > ul li{
    font-size: 10px;
  }

  #main_nav ul li:hover > ul li .sub-side{
    font-size: 20px;
  }

  #main_nav ul ul a:hover{
    background: none;
    color: #d78129;
  }

  .cta-button{
    margin-right: 20px;
  }

  .cta-button a{
    padding: 15px 7px;
    font-size: 12px;
  }

  /*Introduction*/

  .intro-buttons{
    gap: 20px;
    font-size: 12px;
  }

  #dev_team,
  #more_about{
    padding: 20px;
  }

  /*Project Process*/
  

  /*Service Overview*/

  .s-overview-main{
    margin-top: 50px;
  }

  .services-overview{
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    margin-right: 20px;
  }

  .service{
    border-width: 2px;
  }

  .service-icon span{
    font-size: 70px;
    padding: 20px;
  }

  .service h3{
    font-size: 14px;
  }

  .service p{
    font-size: 12px;
  }

  .service-btn button{
    padding: 20px;
    font-size: 12px;
  }

  .bottom-mover{
    margin-bottom: 0;
  }

  .respo-below520{
    margin-bottom: 50px;
  }

  /*Technologies*/

  .technologies-nav{
    margin-bottom: 50px;
  }

  .technologies-images{
    row-gap: 0;
  }

  .technology-image p{
    color: #d78129;
    font-size: 11px;
  }

  /*Recent Projects*/

  .rp-grid-area {
    margin: 0 20px;
  }

  /*Client Recognition*/

  .client-recognition-left{
    margin-left: 7px;
    margin-right: 7px;
  }
  
  .client-recognition-left p{
    margin-bottom: 10px;
  }

  .brand-logos{
    display: flex;
    flex-direction: column;
  }

  .brand-logos img{
    width: 250px;
    height: 250px;
  }
  
  .client-recognition-btn a{
    font-size: 14px;
    padding: 20px;
  }

  /*Client Testimonials*/

  .client-testimonials-section{
    margin-top: 50px;
  }

  .client-testimonials-section .testi-wrapper{
    background: url("/public/assets/lbop_bg.png") no-repeat center;
    background-size: cover;
    margin: 10px;
    padding: 10px;
  }
  
  .client-testimonials-intro{
    text-align: center;
    margin: 0 10px 50px 10px;
  }

   .client-images img{
    width: 50px;
    height: 50px;
  }
  
  .client-images img.active{
    width: 70px;
    height: 70px;
  }

  .client-testimonials .testimonial-content h3{
    color: #081b29;
    font-size: 16px;
  }

  .testimonial-content p{
    margin-bottom: 20px;
  }

  /*Why Choose*/

  .why-choose-section{
    margin-top: 50px;
  }

  .why-choose-reasons{
    display: flex;
    flex-direction: column;
  }
  
  .why-choose-reasons .reason-card{
    padding: 20px 70px;
    width: 250px;
    height: 250px;
  }

  .reason-card h3{
    font-size: 28px;
    white-space: nowrap;
  }

  .reason-card h4{
    font-size: 18px;
    white-space: nowrap;
  }
  
  .reason-card:hover{
    box-shadow: 0 0 10px #0071bc;
  }

  .final-reason{
    margin-top: 20px;
  }

  .final-reason .reason-card{
    padding: 20px 70px;
    width: 250px;
    height: 250px;
  }

  /*Lead Generation*/

  .form-area{
    margin-left: 10px;
    margin-right: 10px;
  }

  .top-row{
    gap: 15px;
  }
  
  .inside input, .inside select{
    width: 300px;
  }
  
  .middle-row{
    gap: 15px;
  }
  
  .middle-row select{
    width: 380px;
  }
  
  .bottom-row .inside #message{
    width: 300px;
  }

/*About Page Responsive Styling Begins Here*/

  /**About Introduction**/
  
  .about-intro-left, .about-intro-right{
    margin-left: 10px;
    margin-right: 10px;
  }

  .about-intro-left a{
    font-size: 13px;
  }

  /**About Detailed**/

  .about-detailed{
    margin-top: 50px;
  }

  .about-detailed-content{
    margin-left: 10px;
    margin-right: 10px;
  }

  .about-detailed-content p{
    text-align: center;
  }

  /**About Culture**/

  .about-culture-content{
    margin-right: 10px;
    margin-left: 10px;
  }

  .core-values{
    margin-right: 20px;
    margin-left: 20px;
  }

  /**About Team**/

  .about-team-content{
    margin-right: 10px;
    margin-left: 10px;
  }

  .team-members{
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
  }

  .team-members .team-member{
    gap: 10px;
    padding: 25px;
    padding-bottom: 40px;
  }

  .team-members .team-member img{
    width: 100px;
    height: 100px;
  }

  .team-members .team-member h3{
    font-size: 16px;
  }

  .team-members .team-member p{
    font-size: 14px;
  }


/*About Page Responsive Styling Ends Here*/


/*Hire A Developer Page Responsive Styling Begins Here*/

  .hire-dev-right .hire-dev-box{
    padding: 10px 20px;
  }

  .hire-dev-box-cont .stack-icon i{
    font-size: 90px;
  }

  .hire-dev-box-cont h3:nth-child(2){
    position: relative;
    top: 10px;
    font-size: 20px;
  }

  .hire-dev-box-cont h3:nth-child(3){
    border-width: 1px;
  }

  .hire-dev-box-cont h3:nth-child(3) #larger{
    font-size: 30px;
  }

  .hire-dev-box .hire-dev-btn a{
    padding: 10px 20px;
    font-size: 14px;
  }

/*Hire A Developer Page Responsive Styling Ends Here*/

/*Case Study Page Responsive Styling Begins Here*/

  .case-study-features ul{
    padding: 20px 40px;
  }

  .case-study-features ul li{
    font-size: 18px;
  }

  .case-study-features ul li i{
    right: 5px;
    top: 5px;
    font-size: 30px;
  }

  .case-study-process .process-details{
    padding: 10px 40px;
  }

  .case-study-process .process-details .process-stage{
    gap: 5px;
  }

  .case-study-process .process-details .process-stage h3{
    font-size: 18px;
  }

  .case-study-process .process-details .process-stage ul li{
    font-size: 16px;
    margin-bottom: 5px;
  }

  .case-study-tech-stack{
    padding: 10px;
  }
  
  .case-study-tech-stack .tech-stack-cards{
    gap: 20px;
  }

  .case-study-tech-stack .tech-stack-cards .tech-stack-card h4{
    font-size: 10px;
  }

/*Case Study Page Responsive Styling Ends Here*/

/*Services Pages Responsive Styling Begins Here*/

  .services-technologies{
    background: url("/public/assets/scmobile_servicetech_bg.gif");
    background-size: cover;
  }

  .services-tech-img .technology-image p{
    color: #d78129;
    position: relative;
    top: 5px;
  }

/*Services Pages Responsive Styling Ends Here*/

/*Contact Page Responsive Styling Begins*/

  .contact-btns{
    gap: 50px;
    margin-bottom: 50px;
  }

  #call_btn{
    font-size: 11px;
    padding: 15px 45px;
  }


  #wa_btn{
    font-size: 11px;
    padding: 15px 7px;
  }

/*Contact Page Responsive Styling Ends*/

/*Privacy Policy Page Responsive Styling Begins*/

.privacy-notice{
  margin: 30px;
}

.privacy-notice p{
  font-size: 16px;
}

.privacy-notice h2{
  text-align: center;
}

.privacy-notice h3{
  color: #d78129;
}

.privacy-notice ul{
  line-height: 1.5;
}

/*Privacy Policy Page Responsive Styling Ends*/


  /*Footer*/

  .footer{
    gap: 10px;
  }

  .footer-left{
    margin-left: 5px;
    margin-right: 5px;
  }

  .footer-subscribe .subscribe label{
    font-size: 16px;
  }

  #email_input{
    padding: 10px 40px;
    font-size: 13px;
  }

  #subscribe{
    padding: 10px 30px;
    font-size: 13px;
  }

  .footer-center{
    display: flex;
    flex-direction: column;
  }

  .footer-center .center-left h4,
  .center-right h4{
    font-size: 26px;
  }

  .footer-center .center-left ul li a,
  .center-right ul li a{
    font-size: 14px;
  }

  .footer-right .connect h4{
    font-size: 26px;
  }

  .footer-right .connect ul li a{
    font-size: 14px;
  }

  .footer-lower{
    gap: 30px;
  }
}

/*MAX w 462*/

@media (max-width: 462px){


  /*Header*/

  .logo img{
    max-width: 100px;
    max-height: 100px;
  }

  #menu_icon{
    font-size: 36px;
  }

  #main_nav ul {
    padding: 10px 5px 20px 10px;
  }

  .cta-button{
    margin-right: 20px;
  }

  .cta-button a{
    padding: 15px;
    font-size: 13px;
  }

  /*Project Process*/

  .process-section {
    margin-top: 80px;
  }

  /*Service Overview*/

  .s-overview-main{
    margin-top: 40px;
  }

  .service p{
    font-size: 12px;
  }

  .service-btn button{
    font-size: 11px;
  }

  /*Technologies*/

  .technologies-intro{
    margin: 0 20px 50px 20px;
  }

  .technologies-nav ul {
    display: grid;
    grid-template-columns: repeat(3, 2fr);
    row-gap: 20px;
  }

  .technologies-nav li {
    margin: 0;
    text-align: center;
  }

  .technologies-nav a {
    font-size: 11px;
  }

  .technologies-images {
    row-gap: 20px;
  }

  .technology-image img {
    width: 100px;
    height: 100px;
  }

  /*Testimonials*/

  .client-testimonials-section{
    margin-top: 70px;
  }
  
  .client-testimonials{
    display: none;
  }

  .mobile-testimonials{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .mobile-testimonials img{
    width: 200px;
    height: 200px;
    border: 2px solid #081b29;
    border-radius: 50%;
  }

  .mobile-testimonials h3{
    color: #081b29;
    font-size: 24px;
    margin-top: 20px;
    text-align: center;
  }

  .mobile-testimonials h4{
    color: #d78129;
    font-size: 14px;
    text-align: center;
  }

  .mobile-testimonials p{
    text-align: center;
    margin-bottom: 20px;
  }
  
  /*Why Choose*/
  
  .why-choose-reasons .reason-card{
    padding: 10px 50px;
    width: 250px;
    height: 200px;
  }

  .final-reason .reason-card{
    padding: 10px 50px;
    width: 250px;
    height: 200px;
  }

/*About Page Responsive Styling Begins Here*/

  /**About Culture**/

  .about-culture-content{
    margin-right: 5px;
    margin-left: 5px;
  }

  .core-values{
    margin-right: 10px;
    margin-left: 10px;
  }

  .core-values .core-value i{
    font-size: 50px;
  }

  .core-values .core-value h3{
    font-size: 18px;
  }

  /**About Team**/

  .about-team-content{
    margin-right: 5px;
    margin-left: 5px;
  }

  .team-members{
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .team-members .team-member{
    gap: 20px;
    padding: 40px;
    padding-bottom: 50px;
  }

  .team-members .team-member img{
    width: 120px;
    height: 120px;
  }

  /**About Lead Gen Mini**/

  .lead-gen-mini-main{
    gap: 50px;
    margin-left: 20px;
    margin-right: 20px;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  
  .lead-gen-mini-main .lead-gen-mini-btn a{
    padding: 20px;
  }

/*About Page Responsive Styling Ends Here*/

/*Blog Page Responsive Styling Begins Here*/

.maintenance-container .maintenance-content{
  margin: 70px 20px;
  padding: 10px;
}

.maintenance-content .maintenance-icon{
  padding: 10px;
}

.maintenance-content .maintenance-btns a, 
.maintenance-content .maintenance-btns button {
  padding: 20px;
  font-size: 13px;
}

/*Blog Page Responsive Styling Ends Here*/


/*Industries Page Responsive Styling Begins Here*/

  /**Industry Lead Gen Mini Styles**/

  .industries-lead-gen-main{
    display: flex;
    flex-direction: column;
    gap: 40px;
    justify-content: center;
    align-items: center;
    padding: 20px;
    margin: 100px 20px;
  }

  .industries-lead-gen-main .industries-lead-gen-icon #right{
    display: none;
  }

  .industries-lead-gen-main .industries-lead-gen-icon #down{
    display: block;
    position: relative;
    top: 30px;
  }

  .industries-lead-gen-main:hover .industries-lead-gen-icon #down{
    left: 0;
    top: 40px;
  }

  .industries-lead-gen-main .industries-lead-gen-btn a{
    white-space: nowrap;
    padding: 50px 100px;
  }

/*Industries Page Responsive Styling Ends Here*/


/*Hire A Developer Page Responsive Styling Begins Here*/


  .hire-dev-right .hire-dev-box .hire-dev-box-cont{
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .hire-dev-intro{
    gap: 0;
  }

  .hire-dev-right .hire-dev-box{
    padding: 20px 70px;
  }

  .hire-dev-box-cont h3:nth-child(2){
    font-size: 24px;
  }

  .hire-dev-box-cont h3:nth-child(3){
    border-left: none;
    border-top: 1px solid #0071bc;
    margin-top: 20px;
  }

  .hire-dev-right .not-decided{
   margin-top: 20px;
  }

  .hire-dev-right .not-decided p{
    margin-bottom: 10px;
    font-size: 11px;
  }

/*Hire A Developer Page Responsive Styling Ends Here*/

/*Case Study Page Responsive Styling Begins Here*/

  .case-study-intro .case-intro-left{
    margin-bottom: 0;
  }

/*Case Study Page Responsive Styling Ends Here*/

/*Services Pages Responsive Begins Here*/

.services-technologies-intro{
  margin-top: 50px;
}

/*Services Pages Responsive Ends Here*/

/*Privacy Policy Page Responsive Styling Begins*/

.privacy-notice{
  gap: 15px;
  margin: 20px;
}

.privacy-notice p{
  font-size: 14px;
  line-height: 1.2;
}

.privacy-notice h2{
  font-size: 22px;;
}

.privacy-notice h3{
  font-size: 18px;
}

.privacy-notice ul{
  font-size: 13px;
}

/*Privacy Policy Page Responsive Styling Ends*/


  /*Footer*/

  .footer-subscribe{
    max-width: 420px;
  }

  .footer-lower{
    display: block;
    text-align: center;
  }
}

/*MAX w 391*/

@media (max-width: 391px){

  /*Header*/

  .logo img{
    max-width: 120px;
    max-height: 120px;
  }

  #menu_icon{
    font-size: 50px;
    margin-right: 20px;
  }

  #main_nav ul {
    padding: 5px 5px 20px 5px;
  }

  #main_nav ul li {
    font-size: 10px;
  }

  #main_nav ul li span{
    font-size: 12px;
  }

  #main_nav ul li:hover > ul li{
    font-size: 8px;
    margin-bottom: 15px;
  }

  #main_nav ul li:hover > ul li .sub-side{
    font-size: 15px;
  }


  .cta-button{
    display: none;
  }

   

  .img-container img{
    width: 300px;
    height: 300px;
  }

  .intro-buttons{
    gap: 5px;
    font-size: 11px;
  }

  #dev_team,
  #more_about{
    padding: 15px 10px;
  }

  /*Project Process*/

  .process-section {
    margin-top: 70px;
  }

  .service h3{
    font-size: 16px;
  }

  /*Technologies*/

  .technologies-intro{
    margin: 0 10px 40px 10px;
  }

  .technologies-nav ul {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 10px;
  }

  .technologies-nav a {
    font-size: 10px;
  }

  .technologies-images {
    row-gap: 10px;
  }

  .technology-image img {
    width: 61px;
    height: 61px;
  }

  /*Client Recognition*/

  .client-recognition-left p{
    margin-bottom: 0;
  }

  .brand-logos img{
    width: 200px;
    height: 200px;
  }
  
  .client-recognition-btn a{
    padding: 20px 10px;
  }

  /*Client Testimonials*/

  .client-testimonials-section .testi-wrapper{
    padding: 5px;
  }

  .client-testimonials-intro h2{
    position: relative;
    top: 10px;
    color: #081b29;
  }

  .mobile-testimonials h3{
    font-size: 20px;
  }

  .mobile-testimonials h4{
    font-size: 11px;
  }

  /*Why Choose*/

  .why-choose-reasons .reason-card{
    padding: 5px 30px;
  }

  .reason-card h3{
    font-size: 24px;
  }

  .reason-card h4{
    font-size: 16px;
  }

  .final-reason .reason-card{
    padding: 5px 30px;
  }

  /*Lead Generation*/

  .lead-gen-intro{
    margin: 0 10px 10px 10px;
  }

  .form-area{
    margin-left: 5px;
    margin-right: 5px;
  }

  .inside label{
    font-size: 15px;
  }
  
  .inside input, .inside select{
    width: 240px;
  }
  
  .middle-row select{
    width: 320px;
  }
  
  .bottom-row .inside #message{
    width: 240px;
  }

  /**About Lead Gen Mini**/

  .lead-gen-mini-main{
    margin-left: 10px;
    margin-right: 10px;
    padding: 20px;
  }
  
  .lead-gen-mini-main .lead-gen-mini-btn a{
    padding: 15px;
    font-size: 13px;
    font-weight: 500;
  }

/*Industries Page Responsive Styling Begin Here*/

  .industry-container .industry-unordered{
    column-gap: 20px;
    row-gap: 10px;
    margin: 10px;
  }
  
  .industry-container .industry-unordered li{
    font-size: 10px;
  }

  /**Circle Spin Animation**/

.industry-container .img-container{
  width: 330px;
  height: 330px;
}

/*Industries Page Responsive Styling Ends Here*/


/*Built By Us Page Responsive Styling Begins Here*/

.built-by-us .recent-projects-intro{
  gap: 5px;
}

.built-by-us .recent-projects-intro :nth-child(1),
.built-by-us .recent-projects-intro :nth-child(3){
  font-size: 14px;
}

.built-projects-grid .project-description p{
  font-size: 11px;
}

/*Built By Us Page Responsive Styling Ends Here*/

/*Services Pages Responsive Begins Here*/

.services-technologies-intro{
  margin-top: 30px;
}

/*Services Pages Responsive Ends Here*/


/*Hire A Developer Responsive Styling Begins Here*/

  .not-decided a{
    text-align: center;
    font-size: 14px;
  }

/*Hire A Developer Responsive Styling Ends Here*/

/*Case Study Page Responsive Styling Begins Here*/

.case-study-features ul{
  padding: 20px;
}

.case-study-features ul li{
  font-size: 16px;
}

/*Case Study Page Responsive Styling Ends Here*/

/*Contact Page Responsive Styling Begins*/

.contact-btns{
  display: flex;
  flex-direction: column;
  gap: 40px;
}

#call_btn{
  padding: 20px 75px;
}


#wa_btn{
  padding: 20px 40px;
}

/*Contact Page Responsive Styling Ends*/

  /*Footer*/

  .footer-subscribe{
    max-width: 370px;
  }

  #email_input{
    padding: 10px 30px;
    font-size: 12px;
  }

  #subscribe{
    padding: 10px 20px;
    font-size: 12px;
  }

  .footer-center .center-left h4,
  .center-right h4,
  .footer-right .connect h4{
    font-size: 24px;
  }

  .footer-center .center-left ul li a,
  .center-right ul li a,
  .footer-right .connect ul li a{
    font-size: 12px;
  }

  .footer-socials{
    gap: 20px;
  }

  .footer-socials a i{
    font-size: 30px;
  }
}

/*Animations*/

@keyframes imgSpinner{
  100%{
      transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes fadeIn{
  0%{
    opacity: 0;
  }

  10%{
    opacity: 0.5;
  }

  20%{
    opacity: 1;
  }

  80%{
    opacity: 1;
  }

  90%{
    opacity: 0.5;
  }

  100%{
    opacity: 0;
  }
}